type CountResponseJson = {
  commentCounts: { [key: string]: number };
  success: boolean;
};

function initCount(countSpan: HTMLElement) {
  const commentsUrl = document.body.dataset.commentsUrl;
  if (commentsUrl) {
    const data = getPostData();
    const request = new XMLHttpRequest();
    request.open("POST", `${commentsUrl}/api/comment/count`, true);
    request.send(JSON.stringify(data));

    request.onload = () => {
      if (request.status === 200) {
        displayCount(countSpan, request.response as string);
      }
    };
  }
}

function displayCount(countSpan: HTMLElement, countResponse: string) {
  try {
    const responseJson = JSON.parse(countResponse) as CountResponseJson;
    if (!responseJson.success) {
      return;
    }
    let count;
    if (
      responseJson.commentCounts[location.pathname] &&
      typeof responseJson.commentCounts[location.pathname] === "number"
    ) {
      count = responseJson.commentCounts[location.pathname];
    } else {
      count = 0;
    }
    countSpan.textContent = `${count} ${count === 1 ? "comment" : "comments"}`;
  } catch (ex) {
    // do nothing
  }
}

function getPostData() {
  return {
    domain: location.host,
    paths: [location.pathname],
  };
}

document.addEventListener("DOMContentLoaded", function () {
  const countSpan = document.getElementById("post__meta-comments-js");
  if (countSpan) {
    initCount(countSpan);
  }
});
