/**
 * Observer for a navigation
 */
class NavObserver {
  private navElem: HTMLElement;
  private content: HTMLElement;
  private readonly activeClass: string;
  private observer: IntersectionObserver;

  private navAnchors: Map<string, HTMLAnchorElement>;
  /**
   * Constructor
   * @param navElem - element where the anchors are
   * @param content - content to observe
   * @param activeClass - css class to add for active elements
   */
  constructor(navElem: HTMLElement, content: HTMLElement, activeClass: string) {
    this.navElem = navElem;
    this.content = content;
    this.activeClass = activeClass;
    this.observer = new IntersectionObserver(e => this.elementIntersecting(e), {
      threshold: 0.75,
    });
    const headings = this.findHeadings();
    if (headings.length >= 0) {
      headings.forEach(h => this.observer.observe(h));
      this.navAnchors = this.findAnchors();
    }
  }

  /**
   * @returns {any[]} array with all headlines from the content
   */
  findHeadings() {
    return Array.from(this.content.getElementsByTagName("h2"));
  }

  /**
   * @return {Map} map - map with all anchors in the nav element
   */
  findAnchors() {
    return new Map(Array.from(this.navElem.getElementsByTagName("a")).map(ele => [decodeURI(ele.hash), ele]));
  }

  elementIntersecting(entries: IntersectionObserverEntry[]) {
    this.elementBecomesVisible(
      entries
        .filter(e => e.isIntersecting && e.intersectionRatio >= 0.75)
        .map(e => {
          return this.navAnchors.get(`#${e.target.id}`);
        })
        .filter(e => e),
    );
    this.elementBecomesInvisible(
      entries
        .filter(e => !e.isIntersecting)
        .map(e => this.navAnchors.get(`#${e.target.id}`))
        .filter(e => e),
    );
  }

  elementBecomesVisible(enteringAnchors: HTMLElement[]) {
    const rev = enteringAnchors.reverse();
    for (let i = 0; i < rev.length; i++) {
      rev[i].parentElement.classList.add(this.activeClass);
    }
  }

  elementBecomesInvisible(leavingAnchors: HTMLElement[]) {
    leavingAnchors.forEach(anchor => anchor.parentElement.classList.remove(this.activeClass));
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const postContent = document.getElementById("post-content-js");
  if (postContent) {
    const pageNav = document.getElementById("page-nav-js");
    if (pageNav) {
      new NavObserver(pageNav, postContent, "page__nav-item--active");
    }
    const postNav = document.getElementById("post-nav-js");
    if (postNav) {
      new NavObserver(postNav, postContent, "post__nav-item--active");
    }
  }
});
