import throttle from "lodash.throttle";

let progressBar: HTMLProgressElement,
  contentNavWrapper: HTMLElement,
  startY: number,
  currentY: number,
  screenHeight: number;
let ticking = false;

function onScroll() {
  if (!ticking) {
    currentY = window.pageYOffset || document.documentElement.scrollTop;
    window.requestAnimationFrame(function () {
      if (currentY >= startY) {
        // the content-nav-wrapper's height might change, so we update max every time
        progressBar.setAttribute("max", (contentNavWrapper.clientHeight - screenHeight).toString());
        progressBar.setAttribute("value", (currentY - startY).toString());
      } else {
        progressBar.setAttribute("value", "0");
      }
      ticking = false;
    });
    ticking = true;
  }
}

function init() {
  startY = contentNavWrapper.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop);
  screenHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  progressBar.setAttribute("max", (contentNavWrapper.clientHeight - screenHeight).toString());
  ticking = false;
}

document.addEventListener("DOMContentLoaded", function () {
  progressBar = document.getElementById("progress-js") as HTMLProgressElement;
  contentNavWrapper = document.getElementById("page__content-nav-wrapper-js");
  if (progressBar && contentNavWrapper) {
    init();
    document.addEventListener("scroll", onScroll);
    window.addEventListener("resize", throttle(init, 500, { leading: true, trailing: true }));
  }
});
