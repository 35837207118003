/**
 * Render all posts into matching columns
 */
import { getCurrentMQ, getViewportWidth, MQs } from "../util/mqHelper";

import throttle from "lodash.throttle";
import { observe } from "./lazyLoader";

let lastKnownWidth: number;

type ColDefinition = {
  length: number;
  elem: HTMLElement;
};

function renderPosts() {
  // only render, if the width has changed (height changes don't matter for the postlist)
  if (!viewportWidthChanged()) return;

  const postList = document.getElementById("postlist-js");
  const posts = Array.from(postList.getElementsByTagName("template"));
  clearCols();
  const numCols = getNumberActiveCols();
  const cols: ColDefinition[] = [];
  for (let i = 1; i <= numCols; i++) {
    cols.push({
      length: 0,
      elem: document.getElementById(`postlist__col${i}-js`),
    });
  }
  addPostsToColumns(posts, cols);

  // make sure, that the lazy loader gets all new images
  cols
    .map(col => col.elem)
    .forEach(elem => Array.from(elem.getElementsByClassName("lazy-media")).forEach(e => observe(e as HTMLElement)));
}

/**
 *
 * @returns {boolean} - true if the viewport width has changed
 */
function viewportWidthChanged() {
  const viewPortWidth = getViewportWidth();
  if (lastKnownWidth === viewPortWidth) {
    return false;
  }
  lastKnownWidth = viewPortWidth;
  return true;
}

/**
 *
 * @returns {number} - number of active cols (depending on the viewport width)
 */
function getNumberActiveCols() {
  const mq = getCurrentMQ();
  if (mq === MQs.small) {
    return 1;
  } else if (mq === MQs.medium) {
    return 2;
  }
  return 3;
}

/**
 * clear content of all cols
 */
function clearCols() {
  for (let i = 1; i <= 3; i++) {
    document.getElementById(`postlist__col${i}-js`).innerHTML = "";
  }
}

/**
 *
 * @param {Array} posts - all posts to be rendered
 * @param {Array} cols - columns which can be used
 */
function addPostsToColumns(posts: HTMLTemplateElement[], cols: ColDefinition[]) {
  if (cols.length === 1) {
    const col = cols[0];
    posts.forEach(post => {
      col.elem.appendChild(post.content.cloneNode(true));
    });
  } else {
    posts.forEach(post => {
      addPostToShortestColumn(post, cols);
    });
  }
}

/**
 * Add given post to the shortest available column
 * @param {Object} post - post to be added
 * @param {Array} cols - all columns
 */
function addPostToShortestColumn(post: HTMLTemplateElement, cols: ColDefinition[]) {
  const shortest = findShortestColumn(cols);
  const postToRender = post.content.cloneNode(true);
  const shortestColElem = shortest.elem;
  shortestColElem.appendChild(postToRender);
  shortest.length += (shortestColElem.lastElementChild as HTMLElement).offsetHeight;
}

/**
 * return the shortest column
 * @param {array} cols
 * @return {object} - the shortest element from the array
 */
function findShortestColumn(cols: ColDefinition[]) {
  let shortest: ColDefinition;
  cols.forEach(col => {
    if (!shortest || col.length < shortest.length) {
      shortest = col;
    }
  });
  return shortest;
}

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementById("postlist-js")) {
    renderPosts();
    // rerender on resize, throttled
    window.addEventListener("resize", throttle(renderPosts, 500, { leading: true, trailing: true }));
  }
});
