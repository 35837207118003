/**
 * All breakpoints
 * @type {{small: number, large: number, xlarge: number, xxlarge: number, medium: number}}
 */
export const MQs: { [key: string]: number } = {
  xxlarge: 160,
  xlarge: 120,
  large: 85.375,
  medium: 64,
  small: 48,
};

import throttle from "lodash.throttle";
let lastViewportHeight: number;

/**
 *
 * @returns {Number} the current breakpoint
 */
export function getCurrentMQ() {
  const widthEm = getViewportWidth();

  // find first MQ that has at least the calculated width (min-width in media-query)
  const mq = Object.keys(MQs).find(mq => {
    return widthEm >= MQs[mq];
  });
  return mq ? MQs[mq] : MQs.small;
}

/**
 *
 * @returns {number} - viewport width in em
 */
export function getViewportWidth() {
  // we define the mqs in em, therefor we first need to calculate the width in ems
  const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const fontSize = parseFloat(getComputedStyle(document.body).fontSize);
  return width / fontSize;
}

function updateViewportSize() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  if (!lastViewportHeight || lastViewportHeight !== vh) {
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    lastViewportHeight = vh;
  }
}

/**
 * Ensure, that the --vh variable is set
 */
document.addEventListener("DOMContentLoaded", function () {
  updateViewportSize();
  // rerender on resize, throttled
  window.addEventListener("resize", throttle(updateViewportSize, 500, { leading: true, trailing: true }));
});
