import { getCurrentMQ, MQs } from "../util/mqHelper";

import throttle from "lodash.throttle";

const ids = {
  content: "post__nav-content-js",
  toggle: "post__nav-toggle-id",
};

let contentElem: HTMLElement;

const getHeight = (el: HTMLElement) => {
  const initialStyle = el.style;
  const el_style = window.getComputedStyle(el),
    el_display = el_style.display,
    el_max_height = el_style.maxHeight.replace("px", "").replace("%", "");

  // if it's not hidden we just return normal height
  if (el_display !== "none" && el_max_height !== "0") {
    return el.offsetHeight;
  }

  // the element is hidden so:
  // making the el block so we can measure its height but still be hidden
  el.style.position = "absolute";
  el.style.visibility = "hidden";
  el.style.display = "block";
  el.style.maxHeight = "initial";

  const height = el.offsetHeight;

  // reverting to the original values
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  el.style = initialStyle;

  return height;
};

const init = (expand: boolean) => {
  const height = getHeight(contentElem);
  contentElem.style.setProperty("--post__nav-content-height", `${height}px`);

  if (expand) {
    const mq = getCurrentMQ();
    if (mq === MQs.small) {
      // navi is shown on small devices by default
      (document.getElementById(ids.toggle) as HTMLInputElement).checked = true;
    }
  }
};

document.addEventListener("DOMContentLoaded", function () {
  contentElem = document.getElementById(ids.content);
  if (contentElem) {
    init(true);
    // rerender on resize, throttled
    window.addEventListener(
      "resize",
      throttle(() => init(false), 500, { leading: true, trailing: true }),
    );
  }
});
