/**
 * Disable scrolling on the body to show a lightbox
 */
import { loadMedia } from "./lazyLoader";

export function showLightbox() {
  document.body.classList.add("lightbox__no-scroll");
}

/**
 * close lightbox
 */
export function closeLightbox() {
  document.body.classList.remove("lightbox__no-scroll");
}

function initLightbox(trigger: HTMLElement) {
  // the lightbox is always a parallel object to the trigger
  //TODO: replace this with a unique ID per lightbox
  const lightbox = trigger.parentElement.getElementsByClassName("lightbox")[0];
  const lightboxContent = lightbox.getElementsByClassName("lightbox__content")[0] as HTMLElement;
  if (lightbox && lightboxContent) {
    trigger.addEventListener("click", () => {
      lightbox.classList.add("lightbox--open");
      // trigger image loading
      loadMedia(lightboxContent);
      showLightbox();
    });
    lightbox.addEventListener("click", () => {
      lightbox.classList.remove("lightbox--open");
      closeLightbox();
    });
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const lightboxTrigger = Array.from(document.getElementsByClassName("lightbox__trigger-js"));

  lightboxTrigger.forEach(initLightbox);
});
